<template>
  <div class="mt-3">
    <div class="flex flex-wrap justify-between items-center mb-1">
      <div class="mb-4 md:mb-0 mr-4">
      </div>
      <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
        <!--limit chooser-->
        <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>
        <div class="w-full sm:w-auto flex">
          <!--search-->
          <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>
        </div>
      </div>
    </div>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

    <!--table-->
    <vs-table
      :class="{ 'animate-pulse': table.loading }"
      :sst="true"
      :data="table.rows"
      @sort="onSort"
      stripe>
      <template slot="thead">
        <vs-th class="whitespace-no-wrap" sort-key="tgl_refund">Tgl. Refund</vs-th>
        <vs-th class="whitespace-no-wrap" sort-key="jml_refund">Jml. Refund</vs-th>
        <vs-th class="whitespace-no-wrap" sort-key="keterangan">Keterangan</vs-th>
        <vs-th class="whitespace-no-wrap" sort-key="username">Created By</vs-th>
        <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap" :data="item.tgl_refund">{{ item.tgl_refund }}</vs-td>
          <vs-td class="whitespace-no-wrap" :data="item.jml_refund">{{ item.jml_refund | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap" :data="item.keterangan">{{ item.keterangan }}</vs-td>
          <vs-td class="whitespace-no-wrap" :data="item.username">{{ item.username }}</vs-td>
          <vs-td class="whitespace-no-wrap" :data="item.created_at">{{ item.created_at }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!--paging-->
    <div>
      <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
      <div class="flex w-full justify-end mt-6 sm:hidden">
        <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
        <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
      </div>
    </div>

    <!--modals-->
    <RefundAdd :isActive.sync="modalAdd.active" @success="getData"/>
    <RefundEdit :isActive.sync="modalEdit.active" @success="getData" :item="modalEdit.item"/>
  </div>
</template>

<script>
import KontrakRefundRepository from '@/repositories/marketing/kontrak/kontrak-refund-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'

export default {
  name: 'TabRefund',
  props: ['isActive'],
  components: {
    RefundAdd: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-refund/RefundAdd'),
    RefundEdit: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-refund/RefundEdit'),
    PagingLimit
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    initData () {
      this.getData()
      this.isDataInited = true
    },

    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)
      params.id_kontrak = this.$route.params.idKontrak
      const idKontrak = this.$route.params.idKontrak

      KontrakRefundRepository.get(idKontrak, params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      const idKontrak = this.$route.params.idKontrak
      KontrakRefundRepository.delete(idKontrak, id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    onPageChange () {
      if (this.isActive) {
        this.getData()
      }
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
